.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
}

.selectedrt {
  color: #af1c51 !important;
  cursor: pointer;
}

.notselectedrt {
  cursor: pointer;
}

.paybtn {
  background-color: #af1c51;
  color: #ffffff;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: 600;
}

.triangle::before {
  content: "";
  display: block;
  padding-top: 86.6%;
  background-color: #af1c51;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.whatsappwidget {
  position: fixed;
}

._2qp0Z {
  bottom: 2px !important;
  right: 15px !important;
  width: 58px !important;
  height: 58px !important;
}

.name {
  color: #af1c51;
}

a {
  color: #0066c0;
}

.selectedIcon {
  color: #af1c51;
}

.disabledTeam {
  pointer-events: none;
  opacity: 0.6;
}

:root {
  --red: #03d47c;
  --lightgreen: #a9e0c9;
  --green: #1a3d32;
  --black: #1a1a1a;
  --white: #ffffff;
}