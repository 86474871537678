.privacy-policy-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 10px;
  margin-left: 20px;
  align-items: center;
}

.privacy-policy-container h1 {
  color: #333;
  text-align: center;
  font-size: 24px;
}

.privacy-policy-container p {
  font-size: 16px;
  margin-bottom: 10px;
}

.privacy-policy-container ol {
  padding-left: 20px;
}

@media only screen and (max-width: 600px) {
  .privacy-policy-container h1 {
    font-size: 20px;
  }

  .privacy-policy-container p {
    font-size: 14px;
  }

  .privacy-policy-container ol {
    padding-left: 10px;
  }
}
