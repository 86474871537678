.help-and-support {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 10px;
  color: #333;
  margin-left: 15px;
}

.help-and-support h1 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
}

.help-and-support p {
  font-size: 16px;
  margin-bottom: 10px;
}

.help-and-support strong {
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .help-and-support h1 {
    font-size: 24px;
  }

  .help-and-support p {
    font-size: 14px;
    margin-left: 9px;
  }
}
