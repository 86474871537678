/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}
:root {
  --red: #b50000;
  --green: #109e38;
}
 */

 /* General Styles */

 /* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Header styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

.logo h1 {
  color: #e60026;
}

/* home-container section styles */
.home-container {
  /* Replace background-color with a background image */
  background-image: url('./assets/Home.jpg');
  color: #a8d9e0;
  border: 1px dashed;
  padding: 300px 200px;
  /* text-align: center; */
  height: 100vh;
  scroll-behavior: smooth; 
  display: flex;
  flex-direction: column; 
  justify-content: center;
}

.home-wrapper{
  border: 1px dashed;
}

.home-container h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.home-container p {
  font-size: 18px;
  margin-bottom: 30px;
}

.home-container button {
  background-color: #fff;
  color: #e60026;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.why-us{
  background-image: url('./assets/why-us.jpg');
  color: #f5f5ca;
  padding: 300px 250px;
  top: 200px;
  height: 100vh;
  scroll-behavior: smooth; 
  display: flex;
  flex-direction: column; 
  justify-content: center;
}
.mission{
  background-image: url('./assets/mission.jpg');
  color: #ff5d9e;
  padding: 300px 250px;
  top: 200px;
  height: 100vh;
  scroll-behavior: smooth; 
  display: flex;
  flex-direction: column; 
  justify-content: center;
}
.contact{
  background-image: url('./assets/contact.jpg');
  color: #f5fbb5;
  padding: 300px 250px;
  top: 200px;
  height: 100vh;
  scroll-behavior: smooth; 
  display: flex;
  flex-direction: column; 
  justify-content: center;
}
.contact-wrapper{
  display: flex;
 justify-content: center;
  align-items: center;
  padding: 20px;
}